import React, { ComponentType } from 'react';

import { Box, Slide, useMediaQuery, useTheme } from '@material-ui/core';
import { Redirect, Route, RouteProps as ReactDOMRouteProps } from 'react-router-dom';

import { useSelfJourneyNavigation } from '../../hooks/use-self-journey-navigation/use-self-journey-navigation';
import SelfJourneyHeader from '../self-journey-header/self-journey-header';
import SelfJourneySidebar from '../self-journey-sidebar/self-journey-sidebar';
import { useStyles } from './self-journey-route.styles';

interface Props extends ReactDOMRouteProps {
  component: ComponentType;
}

export const SelfJourneyRoute = ({ component: Component, ...rest }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { shouldGoTo } = useSelfJourneyNavigation();

  return (
    <Route
      {...rest}
      render={() => {
        if (shouldGoTo) {
          return (
            <Redirect
              to={{
                pathname: shouldGoTo,
              }}
            />
          );
        }

        return (
          <Box className={classes.container}>
            <Slide direction='right' in={mediumScreen} mountOnEnter unmountOnExit>
              <Box className={classes.sidebarContainer}>
                <SelfJourneySidebar />
              </Box>
            </Slide>
            <Box className={classes.mainContentContainer}>
              <SelfJourneyHeader />
              <Component />
            </Box>
          </Box>
        );
      }}
    />
  );
};
