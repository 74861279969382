import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      margin: theme.spacing(4, 2),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerText: {
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    link: {
      marginLeft: theme.spacing(0.5),
    },
  })
);
