import { useTheme } from '@material-ui/core';

import { ColorOptions } from '../../../model/theme-options';

type PathType = Partial<{
  [key in ColorOptions]: string;
}>;

interface UseGetColor {
  (color: ColorOptions): string;
}

export const useGetColor: UseGetColor = (color) => {
  const theme = useTheme();

  const colorOptions: PathType = {
    default: theme.palette.grey[800],
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    success: theme.palette?.success?.main,
    successDark: theme.palette?.success?.darkest,
    warning: theme.palette?.warning?.main,
    error: theme.palette?.error?.main,
    light: theme.palette.common.white,
    greyBase: theme.palette?.neutral?.superDarkest,
    transparent: 'transparent',
  };

  return colorOptions[color] as string;
};
