import { useLayoutEffect, useState, useContext } from 'react';

import { useLocation } from 'react-router-dom';

import SelfJourneyPageContext from '../../components/contexts/self-journey-page-context';

interface SelfJourneyNavigation {
  (): {
    shouldGoTo: string;
  };
}

export const useSelfJourneyNavigation: SelfJourneyNavigation = () => {
  const [shouldGoTo, setShouldGoTo] = useState('');
  const { pathname } = useLocation();
  const { currentSelfJourneyPage } = useContext(SelfJourneyPageContext);

  useLayoutEffect(() => {
    if (currentSelfJourneyPage) {
      setShouldGoTo('');
      return;
    }

    if (pathname === '/business-data') {
      setShouldGoTo('/accounts');
    } else if (pathname === '/address') {
      setShouldGoTo('/business-data');
    } else if (pathname === '/finish-self-journey') {
      setShouldGoTo('/address');
    } else {
      setShouldGoTo('');
    }
  }, [currentSelfJourneyPage, pathname]);

  return {
    shouldGoTo,
  };
};
