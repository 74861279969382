import React from 'react';

import { Box, Fade, Typography } from '@material-ui/core';

import Logo from '../../../assets/pede-pronto-logo-large.png';
import { useSelfJourneyAvatarContent } from '../../hooks/use-self-journey-avatar-content/use-self-journey-avatar-content';
import SelfJourneyItem from '../self-journey-item/self-journey-item';
import { useStyles } from './self-journey-sidebar.styles';

export default function SelfJourneySidebar() {
  const classes = useStyles();
  const { getSelfJourneyAvatarContent } = useSelfJourneyAvatarContent();

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <img className={classes.logo} src={Logo} alt='Pede Pronto' />
        <Fade in={true} timeout={600}>
          <Box className={classes.textContainer}>
            <Typography variant='h4' className={classes.title}>
              4 etapas para criar sua conta e divulgar seu cardápio digital.
            </Typography>
            {selfJourneySidebarItens.map(({ step, ...rest }) => (
              <SelfJourneyItem
                key={step}
                {...rest}
                avatarContent={getSelfJourneyAvatarContent(step)}
              />
            ))}
          </Box>
        </Fade>
      </Box>
    </Box>
  );
}

const selfJourneySidebarItens = [
  {
    title: 'Dados pessoais',
    text: 'Conte-nos sobre você. Precisamos desses dados para criar sua conta de acesso a ferramenta.',
    step: 1,
  },
  {
    title: 'Dados do negócio',
    text: 'Informações sobre seu negócio são importantes para exibir no cardápio digitial para seu cliente.',
    step: 2,
  },
  {
    title: 'Endereço do estabelecimento',
    text: 'Informe sua localização. Assim, será fácil encontrar seu estabelecimento.',
    step: 3,
  },
  {
    title: 'Criar conta',
    text: 'Crie uma senha forte para acessar sua conta no Pede Pronto.',
    step: 4,
  },
];
