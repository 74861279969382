import React, { ReactNode } from 'react';

import { Avatar, Box, Typography } from '@material-ui/core';

import { useStyles } from './self-journey-item.styles';

interface Props {
  title: string;
  text: string;
  avatarContent: ReactNode;
}

export default function SelfJourneyItem(props: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Avatar className={classes.avatar}>{props.avatarContent}</Avatar>
      <Box className={classes.textContainer}>
        <Typography variant='body2' className={classes.title}>
          {props.title}
        </Typography>
        <Typography variant='body2' className={classes.text}>
          {props.text}
        </Typography>
      </Box>
    </Box>
  );
}
