import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';

import { locationStepMap } from './location-step-map';
import { useStyles } from './self-journey-header.styles';

export default function SelfJourneyHeader() {
  const classes = useStyles();
  const { pathname } = useLocation();

  const step = locationStepMap[pathname];

  return (
    <Box className={classes.header}>
      <Typography variant='overline' className={classes.headerText} color='primary'>
        {`Etapa ${step} de 4`}
      </Typography>
      <Box>
        <Typography variant='overline' className={classes.headerText}>
          Você já tem uma conta?
        </Typography>
        <Link id='self-journey-login-link' to='/' className={classes.link}>
          <Typography variant='overline' className={classes.headerText} color='primary'>
            Entre agora
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}
