import React, { useContext, useCallback, ReactNode } from 'react';

import SelfJourneyPageContext from '../../components/contexts/self-journey-page-context';
import Icon from '../../components/icon/icon';

interface SelfJourneyAvatarContent {
  (): {
    getSelfJourneyAvatarContent(step: number): ReactNode;
  };
}

export const useSelfJourneyAvatarContent: SelfJourneyAvatarContent = () => {
  const { currentSelfJourneyPage } = useContext(SelfJourneyPageContext);

  const getSelfJourneyAvatarContent = useCallback(
    (step: number) => {
      if (!currentSelfJourneyPage || currentSelfJourneyPage <= step) {
        return step;
      }

      return <Icon name='check' color='light' />;
    },
    [currentSelfJourneyPage]
  );

  return {
    getSelfJourneyAvatarContent,
  };
};
