import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(2),
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
    textElement: {
      padding: theme.spacing(1),
    },
    tryContainer: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    },
  });
