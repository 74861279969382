import React, { useEffect, ReactElement, Suspense } from 'react';

import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';

import withErrorBoundary from './common/components/error-boundary/error-boundary';
import { PrivateRoute } from './common/components/private-route/private-route';
import Progress from './common/components/progress/progress';
import { SelfJourneyRoute } from './common/components/self-journey-route/self-journey-route';
import { getLocationUrl } from './common/helpers/get-location-url/get-location-url';
import { pageview } from './common/helpers/gtags/gtags';
import { isAuthenticated } from './common/helpers/is-authenticated/is-authenticated';

const Login = React.lazy(() => import('./pages/Login/components/login/login'));
const ResetPassword = React.lazy(
  () => import('./pages/ResetPassword/components/reset-password/reset-password')
);
const ResetPasswordSuccess = React.lazy(
  () =>
    import('./pages/ResetPasswordSuccess/components/reset-password-success/reset-password-success')
);
const AccountsForm = React.lazy(
  () => import('./pages/Accounts/components/accounts-form/accounts-form')
);
const BusinessDataForm = React.lazy(
  () => import('./pages/BusinessData/components/business-data-form/business-data-form')
);
const AddressContainer = React.lazy(
  () => import('./pages/Address/components/address-container/address-container')
);
const FinishSelfJourneyForm = React.lazy(
  () =>
    import('./pages/FinishSelfJourney/components/finish-self-journey-form/finish-self-journey-form')
);

const Onboarding = React.lazy(
  () => import('./pages/EcOnboarding/components/onboarding/onboarding')
);

const Brands = React.lazy(() => import('./pages/Brands/components/brands/brands'));
const Companies = React.lazy(() => import('./pages/Companies/components/companies/companies'));
const TimeRules = React.lazy(() => import('./pages/TimeRules/components/time-rules/time-rules'));
const PersonalData = React.lazy(
  () => import('./pages/PersonalData/components/personal-data/personal-data')
);

const NotFound = React.lazy(() => import('./pages/NotFound/components/not-found'));

export default function Routes(): ReactElement {
  useEffect(() => {
    let url: URL = getLocationUrl(window.location);

    pageview(url);
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Progress />}>
        <Switch>
          {isAuthenticated() && (
            <Route exact={true} path='/'>
              <Redirect to='/menu' />
            </Route>
          )}
          <Route exact path='/' component={withErrorBoundary(Login)} />
          <Route exact path='/reset-password' component={withErrorBoundary(ResetPassword)} />
          <Route
            exact
            path='/reset-password/success'
            component={withErrorBoundary(ResetPasswordSuccess)}
          />
          <SelfJourneyRoute exact path='/accounts' component={withErrorBoundary(AccountsForm)} />
          <SelfJourneyRoute
            exact
            path='/business-data'
            component={withErrorBoundary(BusinessDataForm)}
          />
          <SelfJourneyRoute exact path='/address' component={withErrorBoundary(AddressContainer)} />
          <SelfJourneyRoute
            exact
            path='/finish-self-journey'
            component={withErrorBoundary(FinishSelfJourneyForm)}
          />
          <Route path='/cadastre-seu-estabelecimento' component={withErrorBoundary(Onboarding)} />
          <Route
            path='/cadastro-comercial-de-estabelecimento'
            component={withErrorBoundary(Onboarding)}
          />
          <PrivateRoute exact path='/brands' component={withErrorBoundary(Brands)} />
          <PrivateRoute exact path='/companies' component={withErrorBoundary(Companies)} />
          <PrivateRoute exact path='/timerules' component={withErrorBoundary(TimeRules)} />
          <PrivateRoute exact path='/settings' component={withErrorBoundary(PersonalData)} />
          <Route exact component={withErrorBoundary(NotFound)} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
