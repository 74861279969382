import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      maxWidth: '28.5rem',
    },
    contentContainer: {
      padding: theme.spacing(0, 5),
    },
    title: {
      color: theme.palette.grey[100],
      fontWeight: theme.typography?.fontWeightMediumBold,
    },
    logo: {
      marginTop: theme.spacing(5),
      maxHeight: '2.5rem',
      width: 'auto',
    },
    textContainer: {
      marginTop: theme.spacing(8.5),
    },
  })
);
