interface LocationStepMap {
  [key: string]: number;
}

export const locationStepMap: LocationStepMap = {
  '/accounts': 1,
  '/business-data': 2,
  '/address': 3,
  '/finish-self-journey': 4,
};
