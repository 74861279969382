import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: '100vh',
      display: 'flex',
    },
    sidebarContainer: {
      background: `linear-gradient(180deg, ${theme.palette?.primary?.dark} 42.89%,${theme.palette?.secondary?.dark} 221.33%)`,
    },
    mainContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      background: theme.palette.neutral?.main,
    },
  })
);
