import firebase from 'firebase/app';

import { getAppConfig } from '../common/helpers/configs/configs';

export function initFirebase() {
  const { firebaseConfig } = getAppConfig();

  firebase.initializeApp({
    ...firebaseConfig,
  });
}
