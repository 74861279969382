export enum ErrorsCode {
  LOGIN_TOKEN_ERROR = 1,
  LOGIN_USER_ERROR = 2,
  DASH_INITIALIZATION = 3,
  END_FREE_SIGN_UP = 4,
  LOGIN_SELF_JOURNEY = 5,
  RESET_PASSWORD = 6,
  ADD_EMAIL_ERROR = 7,
  COMPANY_INFO_ERROR = 8,
  ADD_CNPJ_ERROR = 9,
  END_SIGN_UP_ERROR = 10,
  IMPORT_CSV = 11,
  BACKEND_MENU_UPSERT_ITEM = 12,
  DIGITAL_MENU_UPSERT_ITEM = 13,
  LOAD_BRAND_LIST = 14,
  LOAD_SHOP_LIST = 15,
  LOAD_COMMERCIAL_CONSULTANTS = 16,
  LOAD_ORIGIN_LEADS = 17,
}
