import { AppConfig } from '../../../common/model/config';

export const developmentAppConfig: AppConfig = {
  productPaginationStep: 20,
  rowsPerPageTable: 50,
  maxFileSize: 5242880,
  gmapsSrc:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyDbrvdvZG7e-Ii43jsN8g_EO8z5EQdR2Wc&libraries=places&callback=initMaps',
  firebaseConfig: {
    apiKey: 'AIzaSyCLVjoFxNu0vD_B5Vui5d1I4luAsSw85WU',
    authDomain: 'pedepronto-frontend.firebaseapp.com',
    projectId: 'pedepronto-frontend',
    storageBucket: 'pedepronto-frontend.appspot.com',
    messagingSenderId: '995307375838',
    appId: '1:995307375838:web:7ad6b694ea285be883464f',
    measurementId: 'G-C4YMRDNQY7',
  },
  webAppUrl: 'https://pedepronto.staging.refeicao.online/',
  simpleMinimumChoices: 0,
  productSearchBoxLimit: 5,
  timeout: 30000,
  pollInterval: 5000,
  gtmId: 'GTM-TCNFBZJ',
  gtmEncryptionKey:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuXyZJjO0d3xXGrbeJtBbBXIHtJVl6ndLbBbiIkVGsRRgN/bXfxCqK/F3m9edkZbKwLa1OaSOIyynRfSLzhtrUb0NX7VeHJ5gfMZPhWjV+WlA9REIAIzJ7mtqGqxVqkUFB2KxfzUwKnAsFUk0cUbMRb1XPfgOMaiGaQWCNBVRVqxk335Nnhsck1pFqazpRZjGouZ3/XIfII9SLuNgqZdX6xDBZNi6+1dLBSF6hfQIn8Bg/GY+qxXKMc9rfptb/SCQm0djrlpnjHdKQsv8J8j64GnNYeegoblJOw3lbbBoX+0cebKAoDOrlHHj+tBI3yXKlYIBk34KT7+418ltT+wuRQIDAQAB',
  reCaptchaKey: '6LflSm8eAAAAAAm3Aw5QZWvoCo4DgS58peEdYAAz',
  econboardingUsername: 'kong',
  econboardingPassword: 'mO8fJxYonE4OXedaBKw0',
  optimizeSrc: 'https://www.googleoptimize.com/optimize.js?id=OPT-NWPM8K5',
};
