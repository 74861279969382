import './config/global-config';
import React from 'react';

import 'firebase/analytics';
import { ApolloProvider } from '@apollo/client';

import PageSetup from './common/components/page-setup/page-setup';
import ThemeHandler from './common/components/theme-handler/theme-handler';
import { createApolloClient } from './config/apollo-client';
import Routes from './routes';

import './index.css';
import 'simplebar/dist/simplebar.min.css';

const apolloClient = createApolloClient();

export default function App() {
  return (
    <div>
      <ApolloProvider client={apolloClient}>
        <PageSetup>
          <ThemeHandler>
            <Routes />
          </ThemeHandler>
        </PageSetup>
      </ApolloProvider>
    </div>
  );
}
