import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const colorStyles = makeStyles((theme: Theme) =>
  createStyles({
    default: {
      backgroundColor: theme.palette?.neutral?.main,
      color: theme.palette.common.white,
      fontWeight: theme.typography?.fontWeightMediumBold,
      '&:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette?.neutral?.superDarkest,
      },
    },
    primary: {
      backgroundColor: theme.palette.primary?.dark,
      color: theme.palette.common.white,
      fontWeight: theme.typography?.fontWeightMediumBold,
      '&:hover': {
        backgroundColor: theme.palette.primary?.main,
      },
      '&:active': {
        backgroundColor: theme.palette.primary?.darkest,
      },
      '&:disabled': {
        backgroundColor: theme.palette.neutral?.main,
        color: theme.palette?.neutral?.superDarkest,
      },
    },
    outline: {
      backgroundColor: 'transparent',
      color: theme.palette.primary.dark,
      boxSizing: 'border-box',
      fontWeight: theme.typography?.fontWeightMediumBold,
      border: `1px solid ${theme.palette.primary.dark}`,
      '&:hover': {
        backgroundColor: theme.palette.primary?.light,
        borderColor: theme.palette.primary?.light,
        color: theme.palette.neutral?.lightest,
      },
      '&:active': {
        backgroundColor: theme.palette.primary?.light,
        color: theme.palette.neutral?.lightest,
      },
    },
    greyBase: {
      backgroundColor: theme.palette.neutral?.main,
      color: theme.typography.color?.main,
      fontWeight: theme.typography?.fontWeightMediumBold,
      '&:hover': {
        backgroundColor: theme.palette.neutral?.main,
        color: theme.typography.color?.main,
      },
      '&:active': {
        backgroundColor: theme.palette.neutral?.dark,
      },
      '&:disabled': {
        backgroundColor: theme.palette.neutral?.main,
        color: theme.palette?.neutral?.superDarkest,
      },
    },
    secondary: {
      backgroundColor: theme.palette.neutral?.main,
      color: theme.typography.color?.main,
      fontWeight: theme.typography?.fontWeightMediumBold,
      '&:hover': {
        backgroundColor: theme.palette.neutral?.main,
        color: theme.typography.color?.main,
      },
      '&:active': {
        backgroundColor: theme.palette.neutral?.dark,
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette?.neutral?.superDarkest,
      },
    },
    success: {
      backgroundColor: theme.palette?.success?.dark,
      color: theme.palette.common.white,
      fontWeight: theme.typography?.fontWeightMediumBold,
      '&:hover': {
        backgroundColor: theme.palette?.success?.main,
        color: theme.palette.common.white,
      },
      '&:active': {
        backgroundColor: theme.palette?.success?.darkest,
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette?.neutral?.superDarkest,
      },
    },
    warning: {
      backgroundColor: theme.palette?.warning?.dark,
      color: theme.palette.common.white,
      fontWeight: theme.typography?.fontWeightMediumBold,
      '&:hover': {
        backgroundColor: theme.palette?.warning?.main,
      },
      '&:active': {
        backgroundColor: theme.palette?.warning?.darkest,
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette?.neutral?.superDarkest,
      },
    },
    error: {
      backgroundColor: theme.palette?.error?.dark,
      color: theme.palette.common.white,
      fontWeight: theme.typography?.fontWeightMediumBold,
      '&:hover': {
        backgroundColor: theme.palette?.error?.main,
      },
      '&:active': {
        backgroundColor: theme.palette?.error?.darkest,
      },
      '&:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette?.neutral?.superDarkest,
      },
    },
    light: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
      fontWeight: theme.typography?.fontWeightMediumBold,
      '&:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette?.neutral?.superDarkest,
      },
    },
    transparent: {
      backgroundColor: 'transparent',
      color: theme.typography?.color?.main,
      fontWeight: theme.typography?.fontWeightMediumBold,
      '&:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette?.neutral?.superDarkest,
      },
    },
  })
);

export const sizeStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      padding: theme.spacing(0.25, 0.75),
      fontSize: theme.typography.caption.fontSize,
      fontFamily: theme.typography.caption.fontFamily,
      lineHeight: theme.typography.caption.lineHeight,
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    medium: {
      padding: theme.spacing(0.75, 1.5),
      fontSize: theme.typography.body2.fontSize,
      fontFamily: theme.typography.body2.fontFamily,
      lineHeight: theme.typography.body2.lineHeight,
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    large: {
      padding: theme.spacing(1, 1.75),
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      lineHeight: theme.typography.body1.lineHeight,
      fontWeight: theme.typography.fontWeightBold as 'bold',
    },
    fullWidth: {
      padding: theme.spacing(1, 1.75),
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      lineHeight: theme.typography.body1.lineHeight,
      fontWeight: theme.typography.fontWeightBold as 'bold',
      width: '100%',
    },
  })
);

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textTransform: 'none',
    },
  })
);
