import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(5),
      display: 'flex',
    },
    avatar: {
      backgroundColor: theme.palette?.primary?.darkest,
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(1),
    },
    title: {
      color: theme.palette.grey[100],
      fontWeight: theme.typography?.fontWeightMediumBold,
    },
    text: {
      color: theme.palette?.primary?.light,
    },
  })
);
