import { AppConfig } from '../../../common/model/config';

export const productionAppConfig: AppConfig = {
  sentryDSN: 'https://001a699b7ea8472b9301babbe68986a7@sentry.monitor.onyo.com/30',
  productPaginationStep: 20,
  rowsPerPageTable: 50,
  maxFileSize: 5242880,
  gmapsSrc:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyDbrvdvZG7e-Ii43jsN8g_EO8z5EQdR2Wc&libraries=places&callback=initMaps',
  firebaseConfig: {
    apiKey: 'AIzaSyCLVjoFxNu0vD_B5Vui5d1I4luAsSw85WU',
    authDomain: 'pedepronto-frontend.firebaseapp.com',
    projectId: 'pedepronto-frontend',
    storageBucket: 'pedepronto-frontend.appspot.com',
    messagingSenderId: '995307375838',
    appId: '1:995307375838:web:fb2e2c5bddcbb1e383464f',
    measurementId: 'G-WXG5R1V5SK',
  },
  webAppUrl: 'https://pedepronto.refeicao.online/',
  simpleMinimumChoices: 0,
  productSearchBoxLimit: 10,
  timeout: 30000,
  pollInterval: 5000,
  gtmId: 'GTM-TCNFBZJ',
  gtmEncryptionKey:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuXyZJjO0d3xXGrbeJtBbBXIHtJVl6ndLbBbiIkVGsRRgN/bXfxCqK/F3m9edkZbKwLa1OaSOIyynRfSLzhtrUb0NX7VeHJ5gfMZPhWjV+WlA9REIAIzJ7mtqGqxVqkUFB2KxfzUwKnAsFUk0cUbMRb1XPfgOMaiGaQWCNBVRVqxk335Nnhsck1pFqazpRZjGouZ3/XIfII9SLuNgqZdX6xDBZNi6+1dLBSF6hfQIn8Bg/GY+qxXKMc9rfptb/SCQm0djrlpnjHdKQsv8J8j64GnNYeegoblJOw3lbbBoX+0cebKAoDOrlHHj+tBI3yXKlYIBk34KT7+418ltT+wuRQIDAQAB',
  reCaptchaKey: '6LcnC4YeAAAAABaicq7HqAjpfEiXEhx7YasFalLx',
  econboardingUsername: 'kong',
  econboardingPassword: 'qLpSP5JusHxSTZFveb6g',
  optimizeSrc: 'https://www.googleoptimize.com/optimize.js?id=OPT-NWPM8K5',
};
