import React, { useState, ReactElement, useEffect } from 'react';

import { Box, CssBaseline } from '@material-ui/core';

import { ErrorsCode } from '../../analytics/enum/log-errors-code';
import { logEvent, logEventError } from '../../analytics/helpers/log-event';
import { useGetUserInfoQuery, User } from '../../generated/graphql';
import { getUserId } from '../../helpers/get-user-id/get-user-id';
import { isSuperUser } from '../../helpers/is-super-user/is-super-user';
import Header from '../header/header';
import NetworkError from '../network-error/network-error';
import Progress from '../progress/progress';
import SideBar from '../sidebar/sidebar';
import { useStyles } from './dashboard.styles';

export default function DashBoard(props: React.PropsWithChildren<any>): ReactElement {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { data: getUserInfoQuery, loading, error, refetch } = useGetUserInfoQuery();

  useEffect(() => {
    if (!window.dataLayer?.find((item: any) => item.event === 'initialization')) {
      logEvent('initialization', { user_id: getUserId(localStorage.getItem('onyo-token')) });
    }
  }, [getUserInfoQuery]);

  if (loading) {
    return <Progress />;
  }

  if (error) {
    logEventError({
      error_type: 'initialization',
      error_code: ErrorsCode.DASH_INITIALIZATION,
      error_message: error?.message,
    });

    return (
      <NetworkError
        errorMsg='Ocorreu um erro ao obter os dados. Tente novamente.'
        retry={refetch}
      />
    );
  }

  return (
    <div className={classes.container}>
      <CssBaseline />
      <Header
        user={getUserInfoQuery?.crmUser?.user as User}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box className={classes.contentContainer}>
        <SideBar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          isSuperUser={isSuperUser(getUserInfoQuery?.crmUser?.user?.email as string)}
        />
        {props.children}
      </Box>
    </div>
  );
}
